@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Manrope";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  height: 100%;
}

#root {
  min-height: 100%;
  font-family: "Inter";
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.polygonpath {
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  animation: dash 2s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
